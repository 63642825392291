import React, { Component } from "react"
import Layout from "../../components/layout"
import HeaderBanner from "../../components/common/headerBanner/headerBanner"
import CTABanner from "../../components/common/ctaBanner/ctaBanner"
import * as styles from "./about.module.scss"
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb"
import ModalVideo from "react-modal-video"
import { TM } from "../../components/common/typography/trademark"
import { LearnMoreTagline } from "../../components/common/typography/banner-tagline"

class AboutIronCAPX extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDesktopOrTablet: true,
      isOpen: false,
      isBrowser: false,
    }
    this.openModal = this.openModal.bind(this)
  }

  componentDidMount() {
    this.updateViewport()
    window.addEventListener("resize", this.updateViewport)

    if (typeof window !== `undefined`) {
      this.setState({
        isBrowser: true,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateViewport)
  }

  updateViewport = () => {
    this.setState({ isDesktopOrTablet: window.innerWidth > 812 })
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  render() {
    return (
      <Layout>
        <HeaderBanner
          title={
            <>
              About IronCAP X<TM />
            </>
          }
        />
        <div className={styles.pageContainer}>
          <Breadcrumb
            firstLink="/ironcap-x"
            firstLinkTitle={
              <>
                IronCAP X<TM />
              </>
            }
            secondLink="/ironcap-x/about"
            secondLinkTitle={
              <>
                About IronCAP X<TM />
              </>
            }
          />
          <div className={styles.pageText}>
            <h3>
              The World's First Post-Quantum Emails/Files Encryption and Digital
              Signing Solution
            </h3>
            <p>
              It utilizes the IronCAP
              <TM /> Toolkits as the underlying cryptographic system to ensure
              safety against cyber attacks from not only the classical world of
              computers but also against future attacks from quantum computers.
              IronCAP X<TM /> simply integrates with your everyday email clients
              such as Outlook and Gmail to ensure zero learning curve.
            </p>
            <p>
              We only charge for business usage. For personal usage, simply sign
              up a FREE account and install IronCAP
              <TM /> on your computer where you run your email clients and begin
              sending encrypted emails to your recipients as well as enjoying
              other features.
            </p>
            <button onClick={this.openModal} className={styles.tutorialButton}>
              Click here to watch a video on how to get started.
            </button>
          </div>
        </div>

        {this.state.isBrowser && (
          <ModalVideo
            channel="youtube"
            isOpen={this.state.isOpen}
            videoId="iKSD8MYShsY"
            onClose={() => this.setState({ isOpen: false })}
          />
        )}

        <CTABanner
          tagline={LearnMoreTagline}
          CTA="video"
          CTADetail="how video"
        />
      </Layout>
    )
  }
}

export default AboutIronCAPX
